.link{
  color: color(blue);
}
h1{
  @include get-font(16px,1);
}

section#info{
  z-index: 1;
  .container{
    background: #F5F5F5;
    .row.inner{
      border:1px solid lightgray;
      border-radius: 5px;
      background: white;

      & > div{
        height:232px;
        &:first-of-type{
          border-right:0.5px solid lightgray;
        }
        .left{
          font-family: HelveticaNeue;
          color: #77797C;
          @include get-font(16px,1.3);
          width:200px;
          display: inline-block;
        }
        .right{
          font-family: HelveticaNeue;
          color: #2A2C30;
          @include get-font(16px,1.3);
          display: inline-block;
        }
        &.top-result{
          p{
            color: #4C4F54;
            @include get-font(16px,1.5)
          }
          .result{
            .left{
              color:green;
              @include get-font(24px,1.3);
              width:100px;

            }
            .num{
              color:green;

            }
            .bar{
              transform: translate(-55.5px,2px);
              @include get-font(20px,1.3);
              z-index: 5;
              display: inline-block;

            }
            .bar-graph{
              display: inline-block;
              position: relative;
              z-index: 0;
            }

          }
          button{
            background-color:color(blue) ;
            border-color:color(blue);
            box-shadow: none;
            font-size: 16px;
          }

        }
      }
    }
  }
}

section#report-on{
   cursor: pointer;
   .container{
     background: #F5F5F5;
     h1{
     }
     .row.inner{
       border:1px solid lightgray;
       border-radius: 5px;
       background: white;
       & > div{
         font-family: HelveticaNeue;
         color: #77797C;
         @include get-font(16px,1.3);
         display: inline-block;
         span{
           display: inline-block;
         }
         .first{
           .carrot{
             transform:rotateZ(270deg);
           }
         }
       }
     }
   }
 }
section#report-off{
  cursor: pointer;
  .container{
    background: #F5F5F5;
    h1{
    }
    .carrot{
      transform:rotateZ(90deg);
      display: inline-block;
    }
  }
}
section.report{
  .container{
    background: #F5F5F5;
    h1{
      @include get-font(16px,1);
    }
    .row.inner{
      .head{
        @include get-font(13px,1);
        color: #CFCFCF;
      }
      .image-div{
        .inner{
          border:1px solid lightgrey;
          border-radius: 5px;

          img{
            padding:10px;
            padding-bottom: 0;
            width:100%;
          }
          .row{
            padding-top: 10px;
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
      border:1px solid lightgray;
      border-radius: 5px;
      background: white;
      & > div{
        font-family: HelveticaNeue;
        color: #77797C;
        @include get-font(16px,1.3);
        display: inline-block;
        span{
          display: inline-block;
        }
        .first{
          width:200px;
          @include get-font(16px,1.3);

        }
        .second{

        }
      }
    }
  }
}

section.content-table{
  .container{
    background: #F5F5F5;
    h1{
      
    }
    .row.inner{
      border:1px solid lightgray;
      border-radius: 5px;
      background: white;
      & > div{
        font-family: HelveticaNeue;
        color: #77797C;
        @include get-font(16px,1.3);
        display: inline-block;
        span{
          display: inline-block;
        }
      }
      .table{
        tr:first-of-type >th{
          color: lightgrey;
          text-transform: uppercase;
          font-family: HelveticaNeue;
          font-weight:200;
          &:first-of-type{
            padding-left:40px;
          }
        }
        th{
          border:none;
          &:first-of-type{
            //padding-left:40px;
          }
        }
        td{
          border-top:1px solid #EEE;
          &:first-of-type{
            padding-left:40px;
          }
          &.fixed{
            width:150px;
          }
        }
      }
    }
  }
}