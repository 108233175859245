.container{
  //box-shadow: 12px 0 15px -4px rgba(31, 73, 125, 0.8), -12px 0 8px -4px rgba(31, 73, 125, 0.8);
}
section#hero{
  .container{
    background: url("../images/LandingPage_HeroImage.jpg");
    background-size: cover;
  }
  .search-bar{
    margin:auto;
  }
}

section#about{
  .subhead{
    margin-top:20px;
    margin-bottom: 20px;
    @include get-font(16px,1.5);
    font-family: HelveticaNeue-Medium;
  }
  p{
    color: #77797c;
    @include get-font(13px,1.4);
    font-family: HelveticaNeue;

  &.about-desc{
    @include get-font(16px,1.5);
    color: #2A2C30;
  }

  }
  .icon{
    height:42px;
    width:46px;
    display: inline;
  }
}

section#learn{
  a{
    padding-left: 13px;
    padding-right: 12px;
    &+a{
      border-left:1px solid #d0d1d3;
    }
  }
}
