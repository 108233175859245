body{
  color:#2A2C30;
  font-family: HelveticaNeue;
}

$font-class-slug: font;

@for  $i from 0 through 5 {
  $num:$i*4 + 16;
  .#{$font-class-slug}-#{$num}{
    font-size: #{$num}px
  }
}
a{
  color:#009CDA;
  &:hover,:focus{
    text-decoration: none;
  }
}
.head{
  @include get-font(20px,1.3);
  font-family: HelveticaNeue-Medium;
}

#header{
  font-family: HelveticaNeue-Medium;
  @include get-font(20px,1.4);
}


.heading{
  color:white;
  font-family: HelveticaNeue-Medium;
  @include get-font(32px,1.5);
}

#hero {
  p{
  color:white;
  @include get-font(16px,1.5);
}
}

.search-bar{
  input{
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    padding: 10px 15px;
    @include get-font(16px,1);
    display: inline;
  }
  input[type="text"]{
    color:#939393;
    background: #f3f3f3;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 516px;
    @include get-font(18px,1);
    margin-right: -3px;
  }
  input[type="submit"]{
    color:white;
    background:color(blue);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-left: -3px;
    padding:12px 26px 13px 26px;
    @include get-font(18px,1);
  }
}
