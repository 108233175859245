section#footer{
  a{
    @include get-font(12px,1.5);
  }
  .col-xs-2{
    padding: 0;
    width:14%;
    &:first-of-type{
      padding-right: 20px;
    }
    .footerrow{
       a{
         color: white;
       }
      .footerhead a{
        color: color(blue);
        font-family: HelveticaNeue-Bold;
        font-size: 14px;
      }
    }
  }
  .footercopy, .footercopy a{
    color: color(light-gray);
    @include get-font(14px,1.5);
    a+a{
      padding-left:10px;
      padding-right:10px;
    }
    a:first-of-type{
      padding-right:10px;
    }
  }
}