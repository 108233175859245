section#header{
  position: relative;
  z-index: 10;
  .container{
    box-shadow:9px 0 10px -13px rgba(31,73,125,.8);
    .logo-bar {
      height:70px;
      line-height: 70px;
      .logo,.brand{
        display:inline-block;
        &.zscaler{
          float: left;
          top:50%;
        }
        &.threatlabz{
          float: right;
        }

      }
    }
  }
}