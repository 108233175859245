@mixin get-font($size,$line){
  font-size: $size;
  line-height: $line;
}

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin boxes() {
  .container > .row {
    margin-bottom: 25px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
@mixin box() {
  height: 100%;
  padding: $box-large-padding;
  .right a {
    text-align: center;
    display: block;
  }
  h1.head {
    font-weight: lighter;
    font-size: 24px;
  }
  .subhead {
    font-size: 14px;
    margin-bottom: $box-small-padding;
    font-weight: normal;
  }
  .top-label {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: $box-large-padding;
  }
  .body {
    font-size: 14px;
    p {
      font-size: 14px;
      margin-bottom: $box-small-padding;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .link-text {
    font-weight: lighter;
    font-size: 14px;
  }
}