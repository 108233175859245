h1, h2, h3, h4, h5, h6 {
  font-size: 12px;
//  line-height: 1em;
  line-height: 1.2em;
  margin: 0;
  font-weight: normal;
}

blockquote {
  padding: 0;
  margin: 0;
  border: none;
}
