@function color($key) {
  @if map-has-key($colors, $key) {
    @return map-get($colors, $key);
  }

  @warn "Unknown `#{$key}` in $colors.";
  @return null;
}

// create a button for each color
@each $name, $hex in $colors {
  .btn-#{$name} {
    background: #{$hex};
    border-color: #{$hex};
    &:hover {
      border-color: #{$hex};
      color: #{$hex} !important;
    }
    &:focus {
      color: color(white);
    }
  }

  //.btn-#{$name}-transparent {
  //  color: #{$hex};
  //  border-color: #{$hex};
  //  background: transparent;
  //  &:hover {
  //    background: transparent;
  //    color: #{$hex};
  //  }
  //}

}

// create a button for each color
@each $name, $hex in $colors {
  .btn-new-#{$name} {
    background: #{$hex};
    border-color: #{$hex};
    border:1px solid #{$hex};
    &:hover {
      border-color: #{$hex};
      color: #{$hex} !important;
      text-decoration: none;
    }
    &:focus {
      color: color(white);
    }
  }
}



// create a inverse button for each color
@each $name, $hex in $colors {
  .btn-#{$name}-inverse {
    background: color(white);
    color: #{$hex} !important;
    border-width: 2px;
    border-color: #{$hex};
    &:hover {
      background: #{$hex} !important;
      color: color(white) !important;
    }
    &:focus {
      color: color(white);
    }
  }
}

@each $name, $hex in $colors {
  .btn-background-#{$name} {
    background: #{$hex} !important;
    &:hover {
      //border-color: #{$hex} !important;
    }
    &:focus {
      //color: color(white);
    }
  }
}


// text color
@each $name, $hex in $colors {
  .fg-color-#{$name} {
    color: #{$hex};
  }
}

// bg color
@each $name, $hex in $colors {
  .bg-color-#{$name} {
    background-color: #{$hex};
  }
}