.get-feedback{
  position: absolute;
  z-index: 99;
  .text-center{
    width:100%;
    input{
      width:100%;
    }

    .good{
      .image{
        height:36px;
        width:36px;
        background-image: url("../images/Feedback_Great_Default.png");
        background-repeat:no-repeat;
        background-size: 36px;
        &:hover{
          background-image: url("../images/Feedback_Great_Hover.png");
        }
      }
      &:hover{
        p{
          color: green!important;
        }
      }
    }
    .bad{
      .image{
        height:36px;
        width:36px;
        background-image: url("../images/Feedback_NotGood_Default.png");
        background-repeat:no-repeat;
        background-size: 36px;
      }
      &:hover{
          p{
            color: #00BCE4!important;
          }
        .image{
          background-image: url("../images/Feedback_NotGood_Hover.png");
        }
      }
    }
  }
  & > div{
    background: white;
    border:1px solid lightgrey;
    border-radius: 5px;
    padding: 10px 40px 10px 10px;
    div{
      display: inline-block;
    }
    p{
      font-size: 13px!important;
    }
  }

}
.report-icon{
  &::before{
    width: 32px;
    background-size: 12px 12px;
    background-position: 0 2px;
    content: "";
    background-image: url("../images/Test_Result_Risk_Malicious.png");
    background-repeat:no-repeat ;
    padding-left: 16px;
  }
  &.danger{
    color: red;
    &::before{

    }
  }
  &.okay{
    color: color(dark-green);
    &::before{
      background-image: url("../images/Test_Result_okay.png");
    }
  }
  &.profile{
    &::before{
      background-image: url("../images/GeoMap_urlIcon.png");
    }
  }
}

.report-icon-after{
  &::after{
    width: 32px;
    height: 32px;
    background-size: 12px 12px;
    //background-position: 0 2px;
    content: "";
    background-image: url("../images/info-xxl.png");
    background-repeat:no-repeat ;
    background-position: 5px 2px;
    position: absolute;
  }
}
.drop-shadow{
  position: relative;
  &::before{
    content: "";
    height: 1px;
    margin-left: -80px;
    -webkit-box-shadow: 0 10px 10px #AAA;
    box-shadow: 0 10px 5px lightgrey;
    position: absolute;
    z-index: 5;
    top: -10px;
    @media (min-width: 1200px){
      width: 1170px;
    }
    //md devices
    @media all and (min-width: $md-min) and (max-width: $md-max){
      width: 970px;
    }
    //sm devices
    @media all and (min-width: $sm-min) and (max-width: $sm-max) {
      width: 750px;
    }
    //xs devices
    @media all and (max-width: $xs-max){
      width: 100%;
    }
  }
}
.mask{
  position: absolute;
  top:0;
  //left:0;
  height:100%;
  @media (min-width: 1200px){
    width: 1170px;
  }
  //md devices
  @media all and (min-width: $md-min) and (max-width: $md-max){
    width: 970px;
  }
  //sm devices
  @media all and (min-width: $sm-min) and (max-width: $sm-max) {
    width: 750px;
  }
  //xs devices
  @media all and (max-width: $xs-max){
    width: 100%;
  }
  z-index: -100;
  box-shadow: 9px 0 10px -13px rgba(31,73,125,.8), -1px 0 10px -4px rgba(31,73,125,.8);
}
.padding-0{
  padding:0!important;
}
.padding-left-0{
  padding-left:0!important;
}
.padding-right-0{
  padding-right:0!important;
}

.margin-0{
  margin:0!important;
}

$class-slug: padding !default;

@for  $i from 0 through 8 {
  $num:$i *4 +16;
  .#{$class-slug}-left-#{$num}{
    padding-left: #{$num}px
  }
  .#{$class-slug}-top-#{$num}{
    padding-top: #{$num}px
  }
  .#{$class-slug}-right-#{$num}{
    padding-right: #{$num}px
  }
  .#{$class-slug}-bottom-#{$num}{
    padding-bottom: #{$num}px
  }

}

$class-slug: margin;

@for  $i from 0 through 8 {
  $num:$i *4 +16;
  .#{$class-slug}-left-#{$num}{
    margin-left: #{$num}px
  }
  .#{$class-slug}-top-#{$num}{
    margin-top: #{$num}px
  }
  .#{$class-slug}-right-#{$num}{
    margin-right: #{$num}px
  }
  .#{$class-slug}-bottom-#{$num}{
    margin-bottom: #{$num}px
  }
}

$class-slug: margin;

@for  $i from 0 through 8 {
  $num:$i*10 + 50;
  .#{$class-slug}-left-#{$num}{
    margin-left: #{$num}px
  }
  .#{$class-slug}-top-#{$num}{
    margin-top: #{$num}px
  }
  .#{$class-slug}-right-#{$num}{
    margin-right: #{$num}px
  }
  .#{$class-slug}-bottom-#{$num}{
    margin-bottom: #{$num}px
  }
}


$class-slug: padding;

@for  $i from 0 through 10 {
  $num:$i*10 + 50;
  .#{$class-slug}-left-#{$num}{
    padding-left: #{$num}px
  }
  .#{$class-slug}-top-#{$num}{
    padding-top: #{$num}px
  }
  .#{$class-slug}-right-#{$num}{
    padding-right: #{$num}px
  }
  .#{$class-slug}-bottom-#{$num}{
    padding-bottom: #{$num}px
  }
}
